import React from 'react';
import PropTypes from 'prop-types';
import { CardGridLeftRightLegacy } from './CardGridLeftRightLegacy';
import { Ad } from '../atoms/Ad';

const STICKY_AD_OPTS = {
  width: '300',
  height: '1050',
  dimensions: '300,1050|300,600|300,250',
  contentType: 'video_search',
  position: '1'
};

export const CardGridLeftRightWithAdLegacy = ({
  cardItems,
  header,
  content
}) => {
  const stickyAd = (
    <Ad
      id="search_side__CardGridLeftRightWithAd"
      content={content}
      key={`${STICKY_AD_OPTS.contentType}_${content.id}_${STICKY_AD_OPTS.position}_content_grid`}
    />
  );

  return cardItems && cardItems.length > 0 ? (
    <CardGridLeftRightLegacy
      header={header}
      leftCardItems={cardItems}
      leftColumns={2}
      right={[stickyAd]}
    />
  ) : null;
};

CardGridLeftRightWithAdLegacy.propTypes = {
  cardItems: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.objectOf(PropTypes.any).isRequired,
      gtmClickEvent: PropTypes.objectOf(PropTypes.any)
    })
  ).isRequired,
  header: PropTypes.string,
  content: PropTypes.objectOf(PropTypes.any).isRequired
};

CardGridLeftRightWithAdLegacy.defaultProps = {
  header: undefined
};
