import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { conf, t } from '../../../domain/services/configService';
import { resourceService } from '../../../domain/services/resourceService';
import { theme } from '../../../domain/theme';
import { mapContentToCardData } from '../../../domain/utils/contentUtils';
import { PreHeadline } from '../atoms/PreHeadline';
import { Headline } from '../atoms/Headline';
import { ContainerMain } from '../atoms/ContainerMain';
import { Loading } from '../atoms/Loading';
import { Warning } from '../atoms/Warning';
import { CardGridLeftRightWithAdLegacy } from '../organisms/CardGridLeftRightWithAdLegacy';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';

// Since this is a template, we can customize its spacings.
// Note that this strategy must be confirmed true when
// developing other pieces of atomic design, so it could change.
const styles = {
  main: css`
    margin-top: ${theme.spacing('xs')};
  `
};

const ITEMS_PER_PAGE = 20;

export const AuthorTemplate = ({ content }) => {
  const [secondaryEntities, setSecondaryEntities] = useState([]);
  const [page, hasMore, setHasMore, loaderRef] = useInfiniteScroll({});

  useEffect(() => {
    const fetchData = async () => {
      const start = page * ITEMS_PER_PAGE;
      const end = start + ITEMS_PER_PAGE;
      const secondaryEntitiesForThisPage = await Promise.all(
        content.secondaryRelatedContents.entities
          .slice(start, end)
          .map(async entity =>
            resourceService.getJsonForPathname(entity.url?.path)
          )
      );
      const filteredSecondaryEntitiesForThisPage =
        secondaryEntitiesForThisPage.filter(entity => entity !== null);

      setHasMore(!!content.secondaryRelatedContents.entities[end + 1]);
      setSecondaryEntities(prev => [
        ...prev,
        ...filteredSecondaryEntitiesForThisPage
      ]);
    };

    if (page !== null && content.secondaryRelatedContents?.entities) {
      fetchData();
    }
  }, [page]);

  const entities = content.mainRelatedContents?.entities
    ? content.mainRelatedContents.entities.concat(secondaryEntities)
    : [];

  let cardItems = [];
  if (entities.length > 1) {
    cardItems = entities.map(entity => ({
      data: mapContentToCardData(entity)
    }));
    cardItems[0].isAboveTheFold = true;
  }
  return (
    <div css={styles.main}>
      <ContainerMain>
        <PreHeadline>
          <span>{t('Author Page')}</span>
        </PreHeadline>
        <Headline>{content.title}</Headline>
        {cardItems.length > 1 && (
          <div>
            <CardGridLeftRightWithAdLegacy
              cardItems={cardItems}
              content={content}
            />
            {hasMore && (
              <div ref={loaderRef}>
                <Loading />
              </div>
            )}
          </div>
        )}

        {entities.length < 2 && conf.isPreview && (
          <Warning>
            This page is not visible on production. It will disappear once site
            is built and published.
          </Warning>
        )}
      </ContainerMain>
    </div>
  );
};

AuthorTemplate.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
