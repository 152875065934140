import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';

const styles = {
  main: css`
    padding: ${theme.spacing('xl')};
    margin: ${theme.spacing('xl')};
    text-align: center;
    background-color: #ffcc00;
    border: 3px dotted #000;
    clear: both;
  `
};

export const Warning = ({ children }) => (
  <div css={styles.main}>{children}</div>
);

Warning.propTypes = {
  children: PropTypes.node.isRequired
};
