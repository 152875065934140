import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';

const styles = {
  main: css`
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('xl2')};
    line-height: ${theme.line.height('s')};
    color: #000;
    ${theme.mq.tablet} {
      font-size: ${theme.font.size('xl3')};
    }
  `
};

export const Headline = ({ children }) => {
  return <h1 css={styles.main}>{children}</h1>;
};

Headline.propTypes = {
  children: PropTypes.node.isRequired
};
