import { useRef, useState, useLayoutEffect } from 'react';

/**
 * An infinite scroller based on effects.
 * Every time the loader is `N`px to be shown, switch to a new page, load new items.
 * @see Based on {@link https://github.com/closeio/use-infinite-scroll}
 *
 * @example
 * const [items, setItems] = useState([]);
 * const [page, hasMore, setHasMore, loaderRef] = useInfiniteScroll({});
 *
 * useEffect(() => {
 *   const fetchData = async () => {
 *     const data = await myApiCall(page);
 *     setHasMore(data.hasMore);
 *     setItems(prev => [...prev, ...data.items]);
 *   };
 *
 *   if (page !== null) {
 *     fetchData();
 *   }
 * }, [page]);
 *
 * return (
 *   <div>
 *     {items.map(item => <div key={item.id}>{item.name}</div>)}
 *     {hasMore && <div ref={loaderRef}>Loading…</div>}
 *   </div>
 * );
 * @param {Object} [options={}]
 * @param {boolean} [options.reset=false] Pass true when you're re-fetching the list and want to resets the scroller to page 0.
 * @param {number} [options.distance=250] When scrolling, the distance in pixels from the bottom to switch the page.
 */
export const useInfiniteScroll = ({ reset = false, distance = 250 }) => {
  const loaderRef = useRef();
  const [page, setPage] = useState(null);
  // The observer will disconnect when there are no more items to load.
  const [hasMore, setHasMore] = useState(true);

  if (reset && page !== null) {
    setPage(null);
  }

  useLayoutEffect(() => {
    const loaderNode = loaderRef.current;
    if (!loaderNode || !hasMore) return undefined;

    const options = {
      rootMargin: `0px 0px ${distance}px 0px`
    };

    let previousY;
    let previousRatio = 0;

    const listener = entries => {
      entries.forEach(
        ({ isIntersecting, intersectionRatio, boundingClientRect = {} }) => {
          const { y } = boundingClientRect;
          if (
            isIntersecting &&
            intersectionRatio >= previousRatio &&
            (!previousY || y < previousY)
          ) {
            setPage(prevPage => {
              return prevPage === null ? 0 : prevPage + 1;
            });
          }
          previousY = y;
          previousRatio = intersectionRatio;
        }
      );
    };

    const observer = new IntersectionObserver(listener, options);
    observer.observe(loaderNode);

    return () => observer && observer.disconnect();
  }, [hasMore, distance]);

  return [page, hasMore, setHasMore, loaderRef];
};
