import React from 'react';
import { css } from '@emotion/react';

const styles = {
  main: css`
    display: block;
    margin: 0 auto;
  `
};

export const Loading = () => (
  <img
    css={styles.main}
    src="/images/ng-logo-animated.gif"
    width="36"
    height="52"
    alt="loading"
  />
);
