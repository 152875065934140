import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';

const styles = {
  main: css`
    display: inline-block;
    width: fit-content;
    min-height: 1.5rem;
    margin-bottom: ${theme.spacing('xs')};
    border-right: 1px solid #999;
    border-left: 1px solid #999;
    padding: ${theme.spacing.parse('0 $xs')};
    span,
    a {
      font-size: ${theme.font.size('xs')};
      letter-spacing: ${theme.letterSpacing('base')};
      line-height: ${theme.line.height('base')};
      ${theme.font.family('boldWeb')};
      text-transform: uppercase;
      color: #000;
    }
  `
};

export const PreHeadline = ({ children }) => {
  return <div css={styles.main}>{children}</div>;
};

PreHeadline.propTypes = {
  children: PropTypes.node.isRequired
};
